.menu-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.title-container{
    align-self: end;
    margin-right: 100px;
    margin-bottom: 50px;
}